<template>
  <v-overlay :value="value">
    <v-progress-circular indeterminate size="125">
      {{ message }}
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    value: Boolean,
    message: String
  }
}
</script>
<style scoped>
.center-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the card takes the full height */
}

.center-title {
  text-align: center;
}
</style>
