var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-overlay",
    { attrs: { value: _vm.value } },
    [
      _c("v-progress-circular", { attrs: { indeterminate: "", size: "125" } }, [
        _vm._v(" " + _vm._s(_vm.message) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }